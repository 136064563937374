<!--
 * @Author: your name
 * @Date: 2021-09-15 20:51:58
 * @LastEditTime: 2021-09-26 17:17:04
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \zhfd-web-sale\src\views\other\help.vue
-->
<!-- 帮助中心 -->
<template>
  <div class="mycontainer">
    <div class="topimg">
      <p class="title">交易常见问题</p>
      <p class="subtitle">解疑答惑，交易步骤一目了然</p>
    </div>
    <div class="content">
      <div v-for="item in listData" :key="item.id" class="box">
        <router-link :to="`helpDetail?id=${item.id}`" class="flex" style="width:100%">
          <div class="text">
            <h3>{{ item.problem }}</h3>
            <p v-html="item.content" />
            <!-- <p>基金交易是T+1个工作日确认，T+2个工作日查询确认结果，建议您可以过两个交易日通...</p> -->
          </div>
          <span class="iconfont left">&#xe6a7;</span>
        </router-link>
      </div>
    </div>
    <pagination
      v-if="total > 0"
      :total="total"
      :page.sync="pageNum"
      :limit.sync="pageSize"
      :layout="layout"
      @pagination="getList"
    />
  </div>
</template>

<script>
import { getComProblem } from '@/api/help'
export default {
  components: {},
  data() {
    return {
      listData: [],
      layout: 'prev, pager, next,sizes',
      total: 10,
      pageNum: 1,
      pageSize: 10
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList() {
      getComProblem({ pageNum: this.pageNum, pageSize: this.pageSize })
        .then((res) => {
          if (res.code === 200) {
            this.listData = res.data.data
            this.total = res.data.total
          }
        })
        .catch((err) => {
          console.log('err', err)
        })
    }
  }
}
</script>

<style lang="less" scoped>
.mycontainer {
  background: #fff;
  width: 930px;
  margin-bottom: 40px;
  .topimg {
    padding-left: 92px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: url('~@/static/img/help/bg.png');
    height: 240px;
    overflow: hidden;

    .title {
      font-size: 42px;
      line-height: 42px;
      color: #ffffff;
      text-shadow: 0px 2px 0px #434342;
    }
    .subtitle {
      margin-top: 19px;
      font-size: 18px;
      line-height: 18px;
      color: #ffffff;
    }
  }
  .content {
    padding: 30px;
  }
  .box {
    margin-top: 30px;
    padding: 14px;
    box-shadow: 0px 4px 12px 0px #edf1f7;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p {
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .text {
      width: 100%;
      h3 {
        font-size: 18px;
        font-weight: 700;
        margin-bottom: 24px;
      }
      p {
        font-size: 16px;
        font-weight: 300;
        color: #6b6b6b;
      }
    }
    .left {
      color: #e0e2e8;
      margin-right: 19px;
    }
  }
}
</style> 